<template xmlns="">
  <div>
    <template v-if="isChengdu">
      <el-row class="box_locale" :gutter="24"  v-loading="loading" element-loading-background="rgba(19, 31, 54)">
        <el-col style="display:flex; align-items: center;" :span="6" @click.native="statusClick('0')">
          <el-col :span="9" style="padding-top:12rem;">
            <el-image class="panel-imgs" :src="require('@/view/bigscreen/assets/images/localeNum.png')" />
          </el-col>
          <el-col :span="15" style="text-align:center;">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.totalDeviceInstalledCount:1'
                      :duration='contentData?contentData.totalDeviceInstalledCount+1:1'
                      :separator='null'
                      style="color: #3f8ffd;font-size: 20rem;margin-top:-4rem;"></count-to>
            <div class="panel">监测点数</div>
          </el-col>
        </el-col>
        <el-col  style="display:flex; align-items: center;"  :span="6" @click.native="statusClick('3')">
          <el-col :span="9" style="padding-top:12rem;">
            <el-image class="panel-imgs" :src="require('@/view/bigscreen/assets/images/onlineNum.png')"/>
          </el-col>
          <el-col :span="15" style="text-align:center;">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.onlineDeviceCount:1'
                      :duration='contentData?contentData.onlineDeviceCount+1:1'
                      :separator='null'
                      style="color: #2bd9ff;font-size: 20rem;margin-top:-4rem;"></count-to>
            <div class="panel">在线数</div>
          </el-col>
        </el-col>
        <el-col  style="display:flex; align-items: center;"  :span="6"  @click.native="statusClick('4')">
          <el-col :span="9" style="padding-top:12rem;">
            <el-image class="panel-imgs" :src="require('@/view/bigscreen/assets/images/normalNum.png')"/>
          </el-col>
          <el-col :span="15" style="text-align:center;">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.complianceDeviceCount:1'
                      :duration='contentData?contentData.complianceDeviceCount+1:1'
                      :separator='null'
                      style="color: #08b204;font-size: 20rem;margin-top:-4rem;"></count-to>
            <div class="panel">达标数</div>
          </el-col>
        </el-col>
        <el-col  style="display:flex; align-items: center;"  :span="6"  @click.native="statusClick('5')">
           <el-col :span="9" style="padding-top:12rem;">
            <el-image class="panel-imgs" :src="require('@/view/bigscreen/assets/images/exceedLimitNum.png')"/>
          </el-col>
          <el-col :span="15" style="text-align:center;">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.notComplianceDeviceCount:1'
                      :duration='contentData?contentData.notComplianceDeviceCount+1:1'
                      :separator='null'
                      style="color: #ff0000;font-size: 20rem;margin-top:-4rem;"></count-to>
            <div class="panel">超标数</div>
          </el-col>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row :style="{'background-color': dataObj.level === 'country'|| dataObj.level === 'province' || dataObj.level ==='city' ? '':'#fff', 'opacity': dataObj.level === 'country'|| dataObj.level === 'province' || dataObj.level ==='city' ? '1':'0.9'}" class="data_box" :gutter="24" element-loading-background="rgb(44, 75, 147)" v-loading="loading">
        <el-col :span="6" @click.native="statusClick('0')">
          <el-col :span="12">
            <el-image class="panel-img" :src="require('@/view/bigscreen/assets/images/localeNum.png')"/>
          </el-col>
          <el-col :span="12" style="text-align: center;padding-left: 0;padding-right: 0">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.totalDeviceInstalledCount:1'
                      :duration='contentData?contentData.totalDeviceInstalledCount+1:1'
                      :separator='null'
                      style="color: #3f8ffd;font-size: calc(100vw * 25 / 1920);text-align: center"></count-to>
            <div class="panel-text">监测点数</div>
          </el-col>
        </el-col>
        <el-col :span="6" @click.native="statusClick('3')">
          <el-col :span="12">
            <el-image class="panel-img" :src="require('@/view/bigscreen/assets/images/onlineNum.png')"/>
          </el-col>
          <el-col :span="12" style="text-align: center;padding-left: 0;padding-right: 0">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.onlineDeviceCount:1'
                      :duration='contentData?contentData.onlineDeviceCount+1:1'
                      :separator='null'
                      style="color: #2bd9ff;font-size: calc(100vw * 25 / 1920);text-align: center"></count-to>
            <div class="panel-text">在线数</div>
          </el-col>
        </el-col>
        <el-col :span="6"  @click.native="statusClick('4')">
          <el-col :span="12" >
            <el-image class="panel-img" :src="require('@/view/bigscreen/assets/images/normalNum.png')"/>
          </el-col>
          <el-col :span="12" style="text-align: center;padding-left: 0;padding-right: 0">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.complianceDeviceCount:1'
                      :duration='contentData?contentData.complianceDeviceCount+1:1'
                      :separator='null'
                      style="color: #08b204;font-size: calc(100vw * 25 / 1920);text-align: center"></count-to>
            <div class="panel-text">达标数</div>
          </el-col>
        </el-col>
        <el-col :span="6"  @click.native="statusClick('5')">
          <el-col :span="12">
            <el-image class="panel-img" :src="require('@/view/bigscreen/assets/images/exceedLimitNum.png')"/>
          </el-col>
          <el-col :span="12" style="text-align: center;padding-left: 0;padding-right: 0">
            <count-to :startVal='0'
                      :endVal='contentData?contentData.notComplianceDeviceCount:1'
                      :duration='contentData?contentData.notComplianceDeviceCount+1:1'
                      :separator='null'
                      style="color: #ff0000;font-size: calc(100vw * 25 / 1920);text-align: center"></count-to>
            <div class="panel-text">超标数</div>
          </el-col>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import countTo from 'vue-count-to';
import {
  getUserInfo
} from '@/util/index'
export default {
  name: 'DataPanelCenter',
  components: {
    countTo
  },
  props: {
    sumData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    dataObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
  },
  data() {
    return {
      loading: true,
      isShowExceed: false,
      contentData: undefined,
      showOnline: false,
      showNormal: false,
      showExceed: false,
      isChengdu: false,
    }
  },
  created() {
    this.user = getUserInfo()
    if (this.user.Info && this.user.Info.pid && this.user.Info.pid.slice(0,6) == "/1199/") {
      this.isChengdu = true
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    ...mapState(['bigScreenDataStack'])
  },
  watch: {
    sumData: {
      handler: function (val) {
        this.getData()
      },
      deep: true
    },
  },
  methods: {
    getData() {
      this.loading = true
      if (this.sumData) {
        this.contentData = this.sumData
        this.loading = false
      }
    },
    selectCityChange(area) {
      this.$emit('cityChange', area)
    },
    statusClick(type) {
      if (this.dataObj.level == "street" || this.dataObj.level == "district") {
        if (type == '0') {
          this.showOnline = false
          this.showNormal = false
          this.showExceed = false
        }
        if (type == '3') {
          this.showOnline = !this.showOnline
          if (!this.showOnline) {
            type = '0'
          }
        }
        if (type == '4') {
          this.showNormal = !this.showNormal
          if (!this.showNormal) {
            type = '0'
          }
        }
        if (type == '5') {
          this.showExceed = !this.showExceed
          if (!this.showExceed) {
            type = '0'
          }
        }
        this.$emit('status-change', type)
      }
    },
    switchShowExceed() {
      this.isShowExceed = !this.isShowExceed
    },
    backToPrevious() {
      this.$store.commit('popBigScreenDataStack')
    }
  }
}
</script>

<style lang="scss" scoped>
.box_locale{
  background: linear-gradient(#19253e, #223e61);
  border-radius:16px;
  position: absolute;
  top: 40rem;
  left:  470rem; 
  width: 1010rem;
  z-index: 5;
  height:86rem;
  display: flex;
  align-items: center;
  
}
.box_locale:hover {
  box-shadow: inset 0 0 20px #25a6ff;
}
.data_box {
  position: absolute;
  top: 40rem;
  left:  470rem; 
  width: 1010rem;
  z-index: 4;
  height:100rem;
  display: flex;
  align-items: center;
  .clickable:hover {
    cursor: pointer;
  }
}

.panel-imgs{
  width: 76rem;
}

.panel {
  font-size: 14rem;
  color: #fff;
}

.panel-text {
  font-size: 20rem;
  color: #6581b0;
}
</style>
